import React from "react";
import { Routes, Route } from "react-router-dom";
import MobileLandingPage from "./landing";
import MobileRegistrationForm from "./form";
import MobileThankyouPage from "./thankyou";
function MobileNavigation() {
  return (
    <Routes>
      <Route Component={MobileLandingPage} path="/" />
      <Route Component={MobileRegistrationForm} path="/register-form" />
      <Route Component={MobileThankyouPage} path="/thank-you" />
    </Routes>
  );
}

export default MobileNavigation;
