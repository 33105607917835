import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DesktopFileUpload from "../../components/desktop/fileUpload";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setFirstFormStatus,
  setSecondFormStatus,
  setThirdFormStatus,
} from "../../redux/formStatusStore";
import { SuccessToast } from "../../components/mobile/toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
function DesktopTermsnandConditionsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("thirdStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      dispatch(setSecondFormStatus(true));
      dispatch(setThirdFormStatus(true));
      navigate("/confirm-details");
    }
  }, []);
  const loadInitialValues = () => {
    const savedValues = localStorage.getItem("terms-conditions-info");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          terms: "",
          acceptancename: "",
          signature: "",
        };
  };
  const termsForm = useFormik({
    initialValues: loadInitialValues(),
    validationSchema: Yup.object({
      terms: Yup.string()
        .test(
          "yes",
          `You should accept Terms and Conditions to continue`,
          function (value) {
            return value === "yes";
          }
        )
        .required("This field is required."),
      acceptancename: Yup.string().required("This field is required."),
      signature: Yup.string().required("This field is required."),
    }),
    onSubmit: (values) => {
      dispatch(setThirdFormStatus(true));
      localStorage.setItem("thirdStepSubmitted", true);
      delete values["signature"];
      localStorage.setItem("terms-conditions-info", JSON.stringify(values));
      navigate("/confirm-details");
      SuccessToast("Data Saved! ");
    },
  });

  return (
    <form onSubmit={termsForm.handleSubmit}>
      <div className="p-2 w-full">
        <div className="flex p-2 gap-[25px] h-150px"></div>

        <div className="flex p-2 gap-[25px] h-[924px]">
          <div className="w-full form-container relative bg-white rounded-lg shadow-md px-2 pt-2">
            <div className="bg-[#6457FF] h-10 rounded-t-lg absolute top-0 left-0 right-0 flex items-center">
              <h1 className="text-white p-2">Terms and Conditions</h1>
            </div>
            <div style={{ marginTop: "40px" }}>
              <div className="h-[450px] bg-[#F4F4F4] ml-2 mt-[12px] gap-[24px]">
                <p className="p-[7px] leading-tight">
                  I declare that the information given above is correct and true
                  to the best of my knowledge and belief. If I am selected, I
                  agree to be bound by the rules and regulations of the school
                  now in force and by those to be made from time to time in the
                  future.
                </p>
                <p className="p-[10px] leading-tight">
                  I promise that I will not claim any compensation or refund of
                  the fees paid by me. I also promise that I will refrain from
                  indulging in any activities that are detrimental to the good
                  name of the school, and I submit myself for any action to be
                  taken against me by the school if I indulge in any such
                  activities.
                </p>
                <p className="p-[10px] leading-tight">
                  I promise to pay the school the entire course and other fees
                  prescribed by the school if I fail to continue the course at
                  any time after joining the course.
                </p>
                <p className="p-[10px] leading-tight">
                  I do hereby agree to pay the cost of damage caused to the
                  movable and immovable property of the school due to neglect of
                  duties/work.
                </p>
                <p className="p-[10px] leading-tight">
                  I will not keep myself absent from the classes without
                  obtaining prior permission from the Principal/Director. I am
                  aware that the Tamil Nadu Technical Training School is a
                  self-financing, job-oriented technical training school.
                </p>
                <p className="p-[10px] leading-tight">
                  I shall not use any type of intoxicants/drugs etc., in the
                  premises of the hostel and school and assure to maintain a
                  high standard of character, behaviour, and hygiene during my
                  stay and training period in this school.
                </p>
                <p className="p-[10px] leading-tight">
                  I promise to pay all the fees for the One/Two years course,
                  and in case I fail to pay the fees in time or on demand, the
                  management of Tamil Nadu Technical Training School will have
                  the right to cancel my admission, and I will not proceed in
                  any court of law in this respect.
                </p>
                <p className="p-[10px] leading-tight">
                  I and my parents/guardians have carefully gone through the
                  Prospectus, Rules and Regulations, and Terms and Conditions of
                  Tamil Nadu Technical Training School and, at my own will and
                  desire, please accept my admission form and oblige.
                </p>
              </div>

              <div className="mt-[20px] ml-[10px]">
                <label>
                  Are you agreed Terms and Conditions
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex-1 flex flex-row">
                  <div className="flex items-center p-[10px]">
                    <input
                      type="radio"
                      id="terms"
                      name="terms"
                      value="yes"
                      className="form-radio h-5 w-5"
                      onChange={termsForm.handleChange}
                    />
                    <label htmlFor="male" className="ml-2">
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center p-[10px]">
                    <input
                      type="radio"
                      id="terms_conditions"
                      name="terms"
                      value="no"
                      className="form-radio h-5 w-5"
                      onChange={termsForm.handleChange}
                    />
                    <label htmlFor="female" className="ml-2">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-red-500 font-medium text-md">
                  {termsForm.touched.terms && termsForm?.errors?.terms}
                </p>
              </div>

              <div className="mt-[10px]">
                <label className="ml-[10px] g-[12px]">Declaration</label>
                <div className="h-[92px] bg-[#F4F4F4] ml-2 mt-[12px] gap-[24px] text-indent-8">
                  <p className="p-[10px] leading-tight">
                    I hereby decleare that I hold myself responsible for the
                    timely payment of all dues payable to the Tamilnadu
                    Technical Training School, In Respect of his / her ward
                    named
                    <span className="border-b-1 border-black border-dotted px-2">
                      {termsForm?.values?.acceptancename}
                    </span>
                    during the period of his / her study as per rules of
                    management amended from time to time at the school and there
                    after till the accounts are closed. I hold myself empossible
                    for the desciplinary behaviour of ward.
                  </p>
                </div>
              </div>

              <div className="flex mt-[10px]">
                <div>
                  <label className="ml-[10px]">
                    Enter your name to accept Declarations
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="h-[35px] bg-[#F4F4F4] ml-2 mt-[12px] w-[432px] flex gap-4">
                    <input
                      type="text"
                      name="acceptancename"
                      id="declaration"
                      placeholder="Enter Applicant Name"
                      className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4]"
                      onChange={termsForm.handleChange}
                    />
                  </div>
                  <p className="text-red-500 font-medium text-md">
                    {termsForm.touched.acceptancename &&
                      termsForm?.errors?.acceptancename}
                  </p>
                </div>

                <div className="flex flex-1 mr-5 gap-[24px] p-[12px] flex-row">
                  <div className="flex items-center gap-[24px]">
                    <div>
                      <label className="font-400 text-[15px] leading-[11.72px]">
                        Upload Photo<span className="text-red-500">*</span>
                      </label>
                      <DesktopFileUpload
                        innerText={"Upload Photo"}
                        accept={"image/*"}
                        selectedFileBase64={(file) =>
                          termsForm.setFieldValue("signature", file)
                        }
                      />
                      {termsForm.values.signature ? (
                        <p className="text-green-900 text-nowrap">
                          <FontAwesomeIcon icon={faCheckCircle} /> File Uploaded
                        </p>
                      ) : null}
                      <p className="text-red-500 font-medium text-md">
                        {termsForm.touched.signature &&
                          termsForm?.errors?.signature}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 w-full">
        <div className="flex items-center justify-end bg-white shadow-lg flex p-3 items-center justify-end">
          <Link
            to={"/personal-details"}
            className="bg-white border border-[#6457FF] text-[#000000] font-bold py-2 px-4 mr-4 flex items-center"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.57 6.42969L3.5 12.4997L9.57 18.5697"
                stroke="black"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.4999 12.5H3.66992"
                stroke="black"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </Link>

          <button
            id="submit-btn"
            type="submit"
            className="bg-[#6457FF] text-white font-bold py-2 px-4 mr-4 flex items-center"
          >
            Save Changes
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M14.4302 6.42999L20.5002 12.5L14.4302 18.57"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M3.5 12.5H20.33"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </form>
  );
}

export default DesktopTermsnandConditionsForm;
