import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { setFirstFormStatus } from "../../redux/formStatusStore";
import { FailureToast, SuccessToast } from "../../components/mobile/toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import centerList from "../../json/centerList.json";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function DesktopCourseDetailsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courselist, setCourseList] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("firstStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      navigate("/personal-details");
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const loadInitialValues = () => {
    const savedValues = localStorage.getItem("course-details-info");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          course: "",
          center: "",
          qualification: [
            {
              examname: "",
              regno: "",
              marks: "",
              nameofboard: "",
              school: "",
              from: "",
              to: "",
            },
          ],
        };
  };
  const courseForm = useFormik({
    initialValues: loadInitialValues(),
    validationSchema: Yup.object({
      course: Yup.string().required("This field is required."),
      center: Yup.string().required("This field is required."),
      qualification: Yup.array().of(
        Yup.object().shape({
          examname: Yup.string().required("This field is required."),
          regno: Yup.string().required("This field is required."),
          marks: Yup.number()
            .typeError("Must be a number")
            .required("This field is required.")
            .max(100, "Must be less than or equal to 100"),
          nameofboard: Yup.string().required("This field is required."),
          school: Yup.string().required("This field is required."),
          from: Yup.string().required("This field is required."),
          to: Yup.string().required("This field is required."),
        })
      ),
    }),
    onSubmit: (values) => {
      dispatch(setFirstFormStatus(true));
      localStorage.setItem("firstStepSubmitted", true);
      localStorage.setItem("course-details-info", JSON.stringify(values));
      SuccessToast("Data Saved! ");
      navigate("/personal-details");
    },
  });

  const addQualification = () => {
    const newQualification = {
      examname: "",
      regno: "",
      marks: "",
      nameofboard: "",
      school: "",
      from: "",
      to: "",
    };
    courseForm.setFieldValue("qualification", [
      ...courseForm.values.qualification,
      newQualification,
    ]);
  };

  const removeQualification = (index) => {
    if (courseForm.values.qualification.length === 1) {
      FailureToast("Qualification Must be atleast one!");
      return null;
    }
    const qualifications = [...courseForm.values.qualification];
    qualifications.splice(index, 1);
    courseForm.setFieldValue("qualification", qualifications);
  };

  const HandleUpdateQualificationSection = (index, e) => {
    const { name, value } = e.target;
    courseForm.setFieldValue(`qualification[${index}].${name}`, value);
  };

  return (
    <div className="bg-gray-100 h-full px-10 font-roboto">
      <form className="p-2 w-full" onSubmit={courseForm.handleSubmit}>
        <div className="flex p-2 gap-[25px]">
          <div className="w-full h-[150px] form-container relative bg-white rounded-lg shadow-md px-2 pt-2">
            <div className="bg-[#6457FF] h-10 rounded-t-lg absolute top-0 left-0 right-0 flex items-center">
              <h1 className="text-white p-2">Course Details</h1>
            </div>
            <div className="mt-14 px-2 flex gap-4 items-start">
              <div className="w-full">
                <label htmlFor="course-select" className="block text-gray-700">
                  Course<span className="text-red-500">*</span>
                </label>
                <select
                  id="course-select"
                  name="course"
                  onChange={courseForm.handleChange}
                  defaultValue={courseForm?.values?.course}
                  className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4]"
                >
                  <option disabled="" value="">
                    Select Course
                  </option>
                  {courselist.map((value, index) => (
                    <option
                      value={value.value}
                      selected={value.value === courseForm?.values?.course}
                      key={index}
                    >
                      {value.label}
                    </option>
                  ))}
                </select>
                <p className="text-red-500 font-medium text-md">
                  {courseForm.touched.course && courseForm?.errors?.course}
                </p>
              </div>
              <div className="w-full">
                <label htmlFor="center-select" className="block text-gray-700">
                  Center<span className="text-red-500">*</span>
                </label>
                <select
                  id="center-select"
                  name="center"
                  onChange={courseForm.handleChange}
                  defaultValue={courseForm?.values?.center}
                  className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4]"
                >
                  <option disabled="" value="">
                    Select Topic
                  </option>
                  {centerList.map((value, index) => (
                    <option value={value.value} key={index}>
                      {value.label}
                    </option>
                  ))}
                </select>
                <p className="text-red-500 font-medium text-md">
                  {courseForm.touched.center && courseForm?.errors?.center}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-2 gap-[25px] h-150px"></div>

        <div className="flex p-2 gap-[25px]">
          <div className="w-full form-container relative bg-white rounded-lg shadow-md px-2 pt-2">
            <div className="bg-[#6457FF] h-10 rounded-t-lg absolute top-0 left-0 right-0 flex items-center">
              <h1 className="text-white p-2">
                Educational Qualification Details
              </h1>
            </div>
            <div style={{ marginTop: "40px" }}>
              <div className="flex mr-5 gap-[12px] p-[12px] flex-row accordion">
                <button type="button" onClick={() => addQualification()}>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="1"
                      width="23"
                      height="23"
                      rx="1.5"
                      stroke="#6457FF"
                    />
                    <path
                      d="M7.5 12.5H16.5"
                      stroke="#6457FF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 17V8"
                      stroke="#6457FF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <label>Add Qualification</label>
              </div>
              {courseForm?.values?.qualification &&
                courseForm?.values?.qualification.length > 0 &&
                courseForm.values.qualification.map((items, index) => (
                  <div>
                    <div className="flex justify-between items-center rounded-md bg-primary p-2">
                      <p className="text-medium  text-white">
                        Qualification - {index + 1}
                      </p>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-red-500 bg-white p-1 cursor-pointer"
                        onClick={() => removeQualification(index)}
                      />
                    </div>
                    <div className="mt-2 px-2 flex gap-4 items-start">
                      <div>
                        <label
                          htmlFor="exam-passed-select"
                          className="block text-gray-700"
                        >
                          Name of Examination Passed
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="examname"
                          id="examname"
                          placeholder="Qualified Exam Name"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.[
                              "examname"
                            ]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.[
                            "examname"
                          ] &&
                            courseForm?.errors?.qualification?.[index]?.[
                              "examname"
                            ]}
                        </p>
                      </div>
                      <div>
                        <label
                          htmlFor="registernumber"
                          className="block text-gray-700"
                        >
                          Register Number<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="regno"
                          id="regno"
                          placeholder="Enter Registration Number"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.[
                              "regno"
                            ]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.[
                            "regno"
                          ] &&
                            courseForm?.errors?.qualification?.[index]?.[
                              "regno"
                            ]}
                        </p>
                      </div>
                    </div>

                    <div className="mt-5 px-2 flex gap-4 items-start">
                      <div>
                        <label htmlFor="marks" className="block text-gray-700">
                          Marks(%)<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="marks"
                          id="marks"
                          placeholder="Enter Mark"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.[
                              "marks"
                            ]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.[
                            "marks"
                          ] &&
                            courseForm?.errors?.qualification?.[index]?.[
                              "marks"
                            ]}
                        </p>
                      </div>

                      <div>
                        <label
                          htmlFor="boardname"
                          className="block text-gray-700"
                        >
                          Name of Board<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="nameofboard"
                          id="nameofboard"
                          placeholder="Board you Studied"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.[
                              "nameofboard"
                            ]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.[
                            "nameofboard"
                          ] &&
                            courseForm?.errors?.qualification?.[index]?.[
                              "nameofboard"
                            ]}
                        </p>
                      </div>

                      <div>
                        <label
                          htmlFor="schoolname"
                          className="block text-gray-700"
                        >
                          School / College Name
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="school"
                          id="school"
                          placeholder="Enter School Name"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.[
                              "school"
                            ]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.[
                            "school"
                          ] &&
                            courseForm?.errors?.qualification?.[index]?.[
                              "school"
                            ]}
                        </p>
                      </div>
                    </div>

                    <div className="mt-5 px-2 flex gap-4 items-start mb-[20px]">
                      <div>
                        <label
                          htmlFor="startform"
                          className="block text-gray-700"
                        >
                          Start Form<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="date"
                          name="from"
                          id="startform"
                          placeholder="Select Date"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.["from"]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.[
                            "from"
                          ] &&
                            courseForm?.errors?.qualification?.[index]?.[
                              "from"
                            ]}
                        </p>
                      </div>

                      <div>
                        <label htmlFor="to" className="block text-gray-700">
                          To<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="date"
                          name="to"
                          id="to"
                          placeholder="Select Date"
                          className="form-input h-[35px] w-[394px] rounded-[2px] bg-[#F4F4F4] px-3"
                          onChange={(e) =>
                            HandleUpdateQualificationSection(index, e)
                          }
                          defaultValue={
                            courseForm?.values?.qualification?.[index]?.["to"]
                          }
                        />
                        <p className="text-red-500 font-medium text-md">
                          {courseForm.touched.qualification?.[index]?.["to"] &&
                            courseForm?.errors?.qualification?.[index]?.["to"]}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="p-2 w-full">
          <div className="flex items-center justify-end bg-white shadow-lg flex p-3 items-center justify-end">
            {/* <button className="bg-white border border-[#6457FF] text-[#000000] font-bold py-2 px-4 mr-4 flex items-center">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M12.89 6.37988H5.10999C3.39999 6.37988 2 7.77987 2 9.48987V20.8499C2 22.2999 3.04 22.9199 4.31 22.2099L8.23999 20.0199C8.65999 19.7899 9.34 19.7899 9.75 20.0199L13.68 22.2099C14.95 22.9199 15.99 22.2999 15.99 20.8499V9.48987C16 7.77987 14.6 6.37988 12.89 6.37988Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 9.48987V20.8499C16 22.2999 14.96 22.9099 13.69 22.2099L9.76001 20.0199C9.34001 19.7899 8.65999 19.7899 8.23999 20.0199L4.31 22.2099C3.04 22.9099 2 22.2999 2 20.8499V9.48987C2 7.77987 3.39999 6.37988 5.10999 6.37988H12.89C14.6 6.37988 16 7.77987 16 9.48987Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 5.60999V16.97C22 18.42 20.96 19.03 19.69 18.33L16 16.27V9.48999C16 7.77999 14.6 6.38 12.89 6.38H8V5.60999C8 3.89999 9.39999 2.5 11.11 2.5H18.89C20.6 2.5 22 3.89999 22 5.60999Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Discard
            </button> */}

            <button
              id="submit-btn"
              className="bg-[#6457FF] text-white font-bold py-2 px-4 mr-4 flex items-center"
            >
              Save Changes
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
              >
                <path
                  d="M14.4302 6.42999L20.5002 12.5L14.4302 18.57"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M3.5 12.5H20.33"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DesktopCourseDetailsForm;
