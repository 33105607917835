import React, { useEffect } from "react";
import DesktopFileUpload from "../../components/desktop/fileUpload";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  setFirstFormStatus,
  setSecondFormStatus,
} from "../../redux/formStatusStore";
import { SuccessToast } from "../../components/mobile/toastify";
import { Link, useNavigate } from "react-router-dom";
import bloodgroup from "../../json/bloodgroupList.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function DesktopPersonalDetailsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("secondStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      dispatch(setSecondFormStatus(true));
      navigate("/terms-conditions");
    }
  }, []);
  const loadInitialValues = () => {
    const savedValues = localStorage.getItem("personal-details-info");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          applicantname: "",
          passportsizephoto: "",
          fathername: "",
          fatheroccupation: "",
          fathercontact: "",
          mothername: "",
          mothercontact: "",
          address: "",
          district: "",
          state: "",
          taluk: "",
          pincode: "",
          dob: "",
          adharnumber: "",
          gender: "",
          religion: "",
          bloodgroup: "",
          maritalstatus: "",
          nationality: "",
          mothertongue: "",
          handicapped: "",
          mobile: "",
          email: "",
        };
  };
  const personalForm = useFormik({
    initialValues: loadInitialValues(),
    validationSchema: Yup.object({
      applicantname: Yup.string().required("This field is required."),
      passportsizephoto: Yup.string().required("This field is required."),
      fathername: Yup.string().required("This field is required."),
      fatheroccupation: Yup.string().required("This field is required."),
      fathercontact: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      mothername: Yup.string().required("This field is required."),
      mothercontact: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      address: Yup.string().required("This field is required."),
      district: Yup.string().required("This field is required."),
      state: Yup.string().required("This field is required."),
      taluk: Yup.string().required("This field is required."),
      pincode: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      dob: Yup.string().required("This field is required."),
      adharnumber: Yup.string()
        .matches(/^\d+$/, "Must be only digits")
        .min(12, "Number must be at least 12 digits")
        .required("This field is required"),
      gender: Yup.string().required("This field is required."),
      religion: Yup.string().required("This field is required."),
      bloodgroup: Yup.string().required("This field is required."),
      maritalstatus: Yup.string().required("This field is required."),
      nationality: Yup.string().required("This field is required."),
      mothertongue: Yup.string().required("This field is required."),
      handicapped: Yup.string().required("This field is required."),
      mobile: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      email: Yup.string()
        .email("Invalid email format")
        .required("This field is required."),
    }),
    onSubmit: (values) => {
      dispatch(setSecondFormStatus(true));
      localStorage.setItem("secondStepSubmitted", true);
      localStorage.setItem("personal-details-info", JSON.stringify(values));
      SuccessToast("Data Saved! ");
      navigate("/terms-conditions");
    },
  });
  return (
    <div className="bg-gray-100 h-full px-10 font-roboto">
      <form onSubmit={personalForm.handleSubmit}>
        {console.log("vale", personalForm.values)}
        <div className="flex p-2 gap-[25px]">
          <div className="w-full form-container relative bg-white rounded-lg shadow-md px-2 pt-2">
            <div className="bg-[#6457FF] h-10 rounded-t-lg absolute top-0 left-0 right-0 flex items-center">
              <h1 className="text-white p-2">Personal Details</h1>
            </div>

            <div
              className="flex flex-row w-full mr-5 gap-[12px] p-[12px]"
              style={{ marginTop: "40px" }}
            >
              <div className="flex flex-col w-full mr-5 gap-[12px] p-[12px] flex-row">
                <label
                  htmlFor="qualify-exam-select"
                  className="block text-gray-700"
                >
                  Name of the Applicant(as entered in Hr.sec.Certificates)
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="applicantname"
                  id="name"
                  placeholder="Enter Applicant Name"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.applicantname}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.applicantname &&
                    personalForm?.errors?.applicantname}
                </p>
              </div>

              <div className="flex mr-5 p-[12px]">
                <div className="flex flex-col mt-4">
                  <label className="font-normal text-[15px] leading-[11.72px] whitespace-nowrap">
                    Passport Size Photo<span className="text-red-500">*</span>
                  </label>

                  <div className="mt-3">
                    <DesktopFileUpload
                      innerText={"Upload Photo"}
                      accept={"image/*"}
                      selectedFileBase64={(file) =>
                        personalForm.setFieldValue("passportsizephoto", file)
                      }
                      defaultPhoto={personalForm?.values?.passportsizephoto}
                      preview={true}
                    />
                    {console.log("*******", personalForm?.values)}
                  </div>
                  <p className="text-red-500 font-medium text-md">
                    {personalForm.touched.passportsizephoto &&
                      personalForm?.errors?.passportsizephoto}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-1 mr-5 p-[12px] flex-row">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Name of the Father<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="fathername"
                  id="name"
                  placeholder="Enter Father's Name"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.fathername}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.fathername &&
                    personalForm?.errors?.fathername}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Father Occupation<span className="text-red-500">*</span>
                </label>

                <input
                  type="text"
                  name="fatheroccupation"
                  id="name"
                  placeholder="Enter Father's Occupation"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.fatheroccupation}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.fatheroccupation &&
                    personalForm?.errors?.fatheroccupation}
                </p>
              </div>
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Father Contact Number<span className="text-red-500">*</span>
                </label>

                <input
                  type="text"
                  name="fathercontact"
                  id="name"
                  placeholder="Enter Father's Mobile"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.fathercontact}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.fathercontact &&
                    personalForm?.errors?.fathercontact}
                </p>
              </div>
            </div>

            <div className="flex flex-1 mr-5 p-[12px] flex-row">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Mother Name<span className="text-red-500">*</span>
                </label>

                <input
                  type="text"
                  name="mothername"
                  id="name"
                  placeholder="Enter Mother's Name"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.mothername}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.mothername &&
                    personalForm?.errors?.mothername}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Mother Contact Number<span className="text-red-500">*</span>
                </label>

                <input
                  type="text"
                  name="mothercontact"
                  id="name"
                  placeholder="Enter Mother's Mobile"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  defaultValue={personalForm?.values?.mothercontact}
                  onChange={personalForm.handleChange}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.mothercontact &&
                    personalForm?.errors?.mothercontact}
                </p>
              </div>
            </div>

            <div className="flex flex-1 mr-5 p-[12px] flex-row">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Address<span className="text-red-500">*</span>
                </label>
                <textarea
                  name="address"
                  id=""
                  placeholder="Enter Full Address"
                  rows={8}
                  className="form-input bg-[#F4F4F4] text-[15px] leading-[11.72px] p-2"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.address}
                ></textarea>
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.address &&
                    personalForm?.errors?.address}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <div className="flex flex-row gap-[12px]">
                  <div className="flex-1">
                    <label className="font-400 text-[15px] leading-[11.72px]">
                      District
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="district"
                      id="district"
                      placeholder="Enter District"
                      className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                      onChange={personalForm.handleChange}
                      defaultValue={personalForm?.values?.district}
                    />
                    <p className="text-red-500 font-medium text-md">
                      {personalForm.touched.district &&
                        personalForm?.errors?.district}
                    </p>
                  </div>

                  <div className="flex-1">
                    <label className="font-400 text-[15px] leading-[11.72px]">
                      State
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      placeholder="Enter State"
                      className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                      onChange={personalForm.handleChange}
                      defaultValue={personalForm?.values?.state}
                    />
                    <p className="text-red-500 font-medium text-md">
                      {personalForm.touched.state &&
                        personalForm?.errors?.state}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-[12px]">
                  <div className="flex-1">
                    <label className="font-400 text-[15px] leading-[11.72px]">
                      Taluk
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="taluk"
                      id="taluk"
                      placeholder="Enter Taluk"
                      className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                      onChange={personalForm.handleChange}
                      defaultValue={personalForm?.values?.taluk}
                    />

                    <p className="text-red-500 font-medium text-md">
                      {personalForm.touched.taluk &&
                        personalForm?.errors?.taluk}
                    </p>
                  </div>

                  <div className="flex-1">
                    <label className="font-400 text-[15px] leading-[11.72px]">
                      Pincode
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      id="pincode"
                      placeholder="Enter Pincode"
                      className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                      onChange={personalForm.handleChange}
                      defaultValue={personalForm?.values?.pincode}
                    />
                    <p className="text-red-500 font-medium text-md">
                      {personalForm.touched.pincode &&
                        personalForm?.errors?.pincode}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-1 mr-5 p-[12px] flex-row">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Date of Birth
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder="Enter Date of Birth"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.dob}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.dob && personalForm?.errors?.dob}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Aadhar Card
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="adharnumber"
                  id="adharnumber"
                  placeholder="Enter Aadhar Number"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.adharnumber}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.adharnumber &&
                    personalForm?.errors?.adharnumber}
                </p>
              </div>

              <div className="flex-1 p-[12px]">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Sex
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      className="form-radio  w-full h-5 w-5"
                      onChange={personalForm.handleChange}
                      defaultChecked={personalForm?.values?.gender === "male"}
                    />
                    <label htmlFor="male" className="ml-2">
                      Male
                    </label>
                  </div>
                  <div className="flex items-center p-[10px]">
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      className="form-radio  w-full h-5 w-5"
                      defaultChecked={personalForm?.values?.gender === "female"}
                      onChange={personalForm.handleChange}
                    />
                    <label htmlFor="female" className="ml-2">
                      Female
                    </label>
                  </div>
                  <div className="flex items-center p-[10px]">
                    <input
                      type="radio"
                      id="other"
                      name="gender"
                      value="other"
                      className="form-radio  w-full h-5 w-5"
                      defaultChecked={personalForm?.values?.gender === "other"}
                      onChange={personalForm.handleChange}
                    />
                    <label htmlFor="other" className="ml-2">
                      Other
                    </label>
                  </div>
                </div>
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.gender && personalForm?.errors?.gender}
                </p>
              </div>

              <div></div>
            </div>

            <div className="flex flex-1 mr-5 p-[12px] flex-row">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Religion
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="religion"
                  id="religion"
                  placeholder="Enter Religion"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.religion}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.religion &&
                    personalForm?.errors?.religion}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Blood Group
                  <span className="text-red-500">*</span>
                </label>
                <select
                  id="course-select"
                  name="bloodgroup"
                  className="font-400 text-[15px] leading-[11.72px] form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.bloodgroup}
                >
                  <option disabled="" value="">
                    Select Blood Group
                  </option>
                  {bloodgroup.map((items, index) => (
                    <option key={index} value={items.value}>
                      {items.label}
                    </option>
                  ))}
                </select>
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.bloodgroup &&
                    personalForm?.errors?.bloodgroup}
                </p>
              </div>

              <div className="flex-1 p-[12px]">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Marital Status
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="maritalstatus"
                      name="maritalstatus"
                      value="unmarried"
                      className="form-radio  w-full  h-5 w-5"
                      onChange={personalForm.handleChange}
                      defaultChecked={
                        personalForm?.values?.maritalstatus === "unmarried"
                      }
                    />
                    <label htmlFor="Unmarried" className="ml-2">
                      Unmarried
                    </label>
                  </div>
                  <div className="flex items-center p-[10px]">
                    <input
                      type="radio"
                      id="maritalstatus"
                      name="maritalstatus"
                      value="married"
                      className="form-radio  w-full  h-5 w-5"
                      onChange={personalForm.handleChange}
                      defaultChecked={
                        personalForm?.values?.maritalstatus === "married"
                      }
                    />
                    <label htmlFor="Married" className="ml-2">
                      married
                    </label>
                  </div>
                </div>
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.maritalstatus &&
                    personalForm?.errors?.maritalstatus}
                </p>
              </div>

              <div></div>
            </div>

            <div className="flex flex-1 mr-5 p-[12px] flex-row">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Nationality
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="nationality"
                  id="nationality"
                  placeholder="Enter Nationality in Capital Letter"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.nationality}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.nationality &&
                    personalForm?.errors?.nationality}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Mother Tongue
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="mothertongue"
                  id="mothertongue"
                  placeholder="Enter Mother Tongue"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.mothertongue}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.mothertongue &&
                    personalForm?.errors?.mothertongue}
                </p>
              </div>

              <div className="flex-1 p-[20px]">
                <div className="mb-[10px]">
                  <label className="font-400 text-[15px] leading-[11.72px]">
                    Are you Physically Handicapped?
                    <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="flex">
                  <div className="flex items-center ">
                    <input
                      type="radio"
                      id="handicapped"
                      name="handicapped"
                      value="yes"
                      className="form-radio  w-full h-5 w-5"
                      onChange={personalForm.handleChange}
                      defaultChecked={
                        personalForm?.values?.handicapped === "yes"
                      }
                    />
                    <label htmlFor="Unmarried" className="ml-2">
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center p-[10px]">
                    <input
                      type="radio"
                      id="handicapped"
                      name="handicapped"
                      value="no"
                      className="form-radio  w-full h-5 w-5 "
                      onChange={personalForm.handleChange}
                      defaultChecked={
                        personalForm?.values?.handicapped === "no"
                      }
                    />
                    <label htmlFor="Married" className="ml-2">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.handicapped &&
                    personalForm?.errors?.handicapped}
                </p>
              </div>

              <div></div>
            </div>

            <div className="flex flex-1 flex-row gap-[12px] p-[20px]">
              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Mobile Number
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="Enter Contact Number"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.mobile}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.mobile && personalForm?.errors?.mobile}
                </p>
              </div>

              <div className="flex-1 mr-5 gap-[12px] p-[12px] flex flex-col">
                <label className="font-400 text-[15px] leading-[11.72px]">
                  Email ID
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter Personal Email ID"
                  className="form-input h-[35px] w-full rounded-[2px] bg-[#F4F4F4] px-3"
                  onChange={personalForm.handleChange}
                  defaultValue={personalForm?.values?.email}
                />
                <p className="text-red-500 font-medium text-md">
                  {personalForm.touched.email && personalForm?.errors?.email}
                </p>
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <div className="p-2 w-full">
          <div className="flex items-center justify-end bg-white shadow-lg flex p-3 items-center justify-end">
            <Link
              to={"/course-details"}
              onClick={() => {
                dispatch(setFirstFormStatus(false));
                localStorage.setItem("firstStepSubmitted", false);
                navigate(-1);
              }}
              className="bg-white border border-[#6457FF] text-[#000000] font-bold py-2 px-4 mr-4 flex items-center"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.57 6.42969L3.5 12.4997L9.57 18.5697"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.4999 12.5H3.66992"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </Link>

            <button
              // type="button"
              id="submit-btn"
              className="bg-[#6457FF] text-white font-bold py-2 px-4 mr-4 flex items-center"
            >
              Save Changes
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
              >
                <path
                  d="M14.4302 6.42999L20.5002 12.5L14.4302 18.57"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M3.5 12.5H20.33"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DesktopPersonalDetailsForm;
