import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

function MobileThankyouPage() {
  const [appno, setAppno] = useState(null);
  useEffect(() => {
    const appno = localStorage.getItem("APPNO");
    setAppno(appno);
    localStorage.removeItem("firstStepSubmitted");
    localStorage.removeItem("course-details-info");
    localStorage.removeItem("secondStepSubmitted");
    localStorage.removeItem("personal-details-info");
    localStorage.removeItem("thirdStepSubmitted");
    localStorage.removeItem("terms-conditions-info");
  }, []);
  return (
    <div>
      <div className="min-h-[90vh] flex flex-col items-center justify-center">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-green-700 text-[60px] my-3"
        />
        <p className="text-secondary text-center text-2xl font-medium italic">
          Thank you for choosing our institution to begin your journey toward
          excellence and success.
        </p>
        <p className="text-black text-xl mt-5">
          Your Application Code
        </p>
        <p className="text-black font-medium text-3xl mt-2">{appno}</p>
        <button className="bg-primary px-5 py-3 text-white mt-4 underline font-medium text-lg rounded-lg">
          Explore our website
        </button>
      </div>
    </div>
  );
}

export default MobileThankyouPage;
