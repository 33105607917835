import axios, { all } from "axios";
import { generateAppNumber } from "./generateAppNumber";

const createNewApplication = async (allData) => {
  console.log("***************", allData);
  const ApplicationNumber = generateAppNumber();

  const body = {
    applicant_name: allData.acceptancename,
    father_name: allData.fathername,
    mother_name: allData.mothername,
    father_contact: allData.fathercontact,
    mother_contact: allData.mothercontact,
    address: allData.address,
    district: allData.district,
    pincode: allData.pincode,
    date_of_birth: allData.dob,
    adhar_number: allData.adharnumber,
    religion: allData.religion,
    marital_status: allData.maritalstatus,
    nationality: allData.nationality,
    physically_handicapped: allData.handicapped,
    email: allData.email,
    application_date: new Date(),
    center: allData.center,
    course: allData.course,
    occupation_of_parent: allData.fatheroccupation,
    gender: allData.gender,
    blood_group: allData.bloodgroup,
    mother_tongue: allData.mothertongue,
    applicant_signature_image: "signature_image.png",
    applicant_contact_number: allData.mobile,
    applicant_photo: allData.passportsizephoto,
    qualification_and_marks: JSON.stringify(allData.qualification),
    application_number: ApplicationNumber,
  };
  return await axios
    .post(`${process.env.REACT_APP_API_URL}create_new_application.php`, body)
    .then((res) => {
      if (res.data.success) {
        return ApplicationNumber;
      }
    })
    .catch((err) => {
      console.log("error on axios", err);
    });
};

export default createNewApplication;
